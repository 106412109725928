<template>
  <div class="home-container" v-if="canOpen">
    <GuruBreadcrumb :link-list="linkList" v-if="isPreview"/>
    <GuruBreadcrumb :link-list="studentLinkList" v-if="isAttend"/>
    <DoodleGeneralForm in-progress-message="Loading, Please wait..."
                       :form="doodleForm" no-box="true"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       :hide-action-close="true">
      <div v-if="isPreview" class="has-text-danger has-text-centered">Preview</div>
      <div class="has-text-centered">
        <div class="ins-title">{{currentAssociation.institute.name}}</div>
        <div class="assignment-title">{{assignment.title}}</div>
        <div class="student-title" v-if="isEvaluate">{{student.firstName}} {{student.lastName}}</div>
      </div>
      <component v-bind:is="currentTabComponent" :questionIndex = "questionIndex" :isPreview = "isPreview" :isAttend = "isAttend" :isEvaluate = "isEvaluate"
                 :isAccountOwner="isAccountOwner" :isTeacher="isTeacher" :isInstituteAdmin="isInstituteAdmin" :reachedSubmissionPage="reachedSubmissionPage"
                 :isStudent="isStudent" :currentAssociation="currentAssociation" :assignment="assignment" @loadQuestion="loadQuestion" @updateAssessmentStatus="updateAssessmentStatus"
                 @loadQuestionSummaryPage="loadQuestionSummaryPage" @loadQuestionSubmissionPage="loadQuestionSubmissionPage" @reload="getAssignment"
                @updatePreviewSubmission="updatePreviewSubmission" :submissions="submissions" :studentUnderEvaluation="studentUnderEvaluation"/>
    </DoodleGeneralForm>
    <div v-if="isPreview" class="has-text-centered">
      <button class="button exit-preview" @click="exitPreview">Exit Preview</button>
    </div>
    <div v-if="isEvaluate" class="has-text-centered">
      <button class="button exit-preview" @click="exitPreview">Exit</button>
    </div>
  </div>
</template>

<script>
import formMixin from '../../../assets/javascript/form-mixin'
import langListMixin from '../../../assets/javascript/language-list-mixin'
import GuruBreadcrumb from '../GuruBreadcrumb'
import DoodleGeneralForm from '../../Utils/DoodleGeneralForm'

export default {
  name: 'guruAssignmentPreviewAttend',
  components: {
    DoodleGeneralForm,
    GuruBreadcrumb,
    SUMMARY: () => import('./GuruAssignmentSummary'),
    QUESTION: () => import('./GuruAssignmentQuestion'),
    SUBMISSION: () => import('./GuruAssignmentSubmission')
  },
  props: ['currentAssociation', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher', 'isStudent', 'guruInitialised'],
  inject: ['$validator'],
  mixins: [formMixin, langListMixin],
  data: function () {
    return {
      assignment: {
        status: '',
        submissionStatus: '',
        autoCorrect: false,
        autoScore: false,
        resultType: null
      },
      currentTabComponent: null,
      questionIndex: null,
      submissions: [],
      student: {},
      reachedSubmissionPage: false,
      linkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        },
        {
          title: 'Assignments',
          href: '/guru/ins/' + this.$route.params.instituteCode + '/assignments'
        },
        {
          title: 'Assignment',
          href: '/guru/ins/' + this.$route.params.instituteCode + '/assignments/' + this.$route.params.insAssignmentId
        }
      ],
      studentLinkList: [
        {
          title: 'Guru Home',
          href: '/guru'
        },
        {
          title: 'Institute Home',
          href: '/guru/ins/' + this.$route.params.instituteCode
        }
      ]
    }
  },
  mounted () {
    this.getAssignment()
    this.currentTabComponent = 'SUMMARY'
  },
  beforeDestroy () {

  },
  methods: {
    getAssignment () {
      if (!this.guruInitialised) {
        window._.delay(this.getAssignment, 100)
        return
      }
      let url

      if (this.isAttend) {
        url = '/api/guru-api/assignment/getAssignmentToAttend'
      } else if (this.isPreview) {
        url = '/api/guru-api/assignment/getAssignment'
      } else if (this.isEvaluate) {
        if (this.$store.state.studentUnderEvaluation) {
          url = '/api/guru-api/assignment/getSubmissions'
        } else {
          this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.$route.params.insAssignmentId)
          return
        }
      } else {
        this.$router.push('/guru')
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: url,
        data: {
          instituteCode: this.$route.params.instituteCode,
          assignmentId: this.$route.params.insAssignmentId,
          studentEmail: this.$store.state.studentUnderEvaluation
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          Object.assign(this.assignment, data.assignment)
          if (this.assignment.reachedReview) {
            this.reachedSubmissionPage = true
          }
          if (this.isEvaluate) {
            this.assignment.submissionStatus = data.status
            this.submissions = data.submission
            this.student = data.student
          }
          this.fillSubmissionsForPreview()
        },
        jdaEvent: 'guru-get-assignment',
        jdaLabel: ''
      })
    },
    fillSubmissionsForPreview () {
      if (!this.isPreview) {
        return
      }

      for (let qus in this.assignment.questions) {
        this.submissions.push({ questionId: qus, answer: null, isMarkedForReview: false })
      }
    },
    exitPreview () {
      this.$router.push('/guru/ins/' + this.$route.params.instituteCode + '/assignment/' + this.$route.params.insAssignmentId)
    },
    loadQuestion (questionIndex) {
      this.questionIndex = questionIndex
      this.currentTabComponent = 'QUESTION'
    },
    loadQuestionSummaryPage () {
      if (this.isEvaluate) {
        this.getAssignment()
      }
      this.currentTabComponent = 'SUMMARY'
    },
    loadQuestionSubmissionPage (data) {
      if (this.isAttend) {
        this.submissions = data.submission
      }
      this.reachedSubmissionPage = true
      this.currentTabComponent = 'SUBMISSION'
    },
    updatePreviewSubmission (questionIndex, response) {
      this.submissions.splice(questionIndex, 1, response)
    },
    updateAssessmentStatus (status) {
      this.assignment.submissionStatus = status
    }
  },
  computed: {
    isPreview () {
      return this.$route.params.mode === 'preview' && (this.isAccountOwner || this.isInstituteAdmin || this.isTeacher)
    },
    isAttend () {
      return this.$route.params.mode === 'attend' && (this.isStudent)
    },
    isEvaluate () {
      return this.$route.params.mode === 'evaluate' && (this.isAccountOwner || this.isInstituteAdmin || this.isTeacher)
    },
    canOpen () {
      return this.guruInitialised && (this.isPreview || this.isAttend || this.isEvaluate)
    },
    studentUnderEvaluation () {
      return this.$store.state.studentUnderEvaluation
    }
  }
}
</script>

<style scoped lang="scss">
  .assignment-title {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 15px;
  }

  .student-title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .ins-title {
    font-size: 1.1em;
    font-weight: bold;
  }

  .exit-preview {
    margin-top: 15px;
    font-weight: bold;
  }
</style>
